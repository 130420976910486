// assets
import { IconKey, IconReceipt2, IconBug, IconBellRinging, IconPhoneCall,IconBrandChrome } from '@tabler/icons';
import { getRole } from '../utils/Sessions';
// constant
const icons = {
    IconKey,
    IconReceipt2,
    IconBug,
    IconBellRinging,
    IconPhoneCall,
    IconBrandChrome
};


// ===========================|| EXTRA PAGES MENU ITEMS ||=========================== //
const pages = JSON.parse(getRole());

console.log(pages);

// const pages = {
//     id: 'pages',
//     title: 'Pages',
//     type: 'group',
//     children: [
//         {
//             id: 'authentication',
//             title: 'Authentication',
//             type: 'collapse',
//             icon: icons.IconKey,
//             children: [
//                 {
//                     id: 'login3',
//                     title: 'Login',
//                     type: 'item',
//                     url: '/pages/login/login3',
//                     target: true
//                 },
//                 {
//                     id: 'register3',
//                     title: 'Register',
//                     type: 'item',
//                     url: '/pages/register/register3',
//                     target: true
//                 }
//             ]
//         },
//         {
//             id: 'Payroll',
//             title: 'Paylor',
//             type: 'collapse',
//             icon: icons.IconBug,
//             children: [
//                 {
//                     id: 'sample-page',
//                     title: 'Sample Page',
//                     type: 'item',
//                     url: '/sample-page',
//                     icon: icons.IconBrandChrome,
//                     breadcrumbs: false
//                 },
//                 {
//                     id: 'util-typography',
//                     title: 'Typography',
//                     type: 'item',
//                     url: '/utils/util-typography',
//                     icon: icons.IconTypography,
//                     breadcrumbs: false
//                 }
//             ]
//         }
//     ]
// };
// console.log(pages);

export default pages;
