import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import PropTypes from 'prop-types';


function MessageDialog({
    isOpen,
    isConfirmation,
    title,
    subtitle,
    children,
    handleSubmit,
    handleClose,
}) {
    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            fullWidth sx={{ m: 1 }}
        >
            <DialogTitle>{title} </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{subtitle} </DialogContentText>
                {children}
            </DialogContent>
            <DialogActions>
                {isConfirmation ? <Button variant="contained" color="primary" onClick={handleSubmit} autoFocus >Ok</Button> : null}
                <Button variant="contained" id color="warning" onClick={handleClose} >
                {isConfirmation ? "Cancel" : "OK"}
                </Button>
            </DialogActions>
        </Dialog>
    );

}
MessageDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isConfirmation: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
}


export default MessageDialog;