import React,{useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import http from "../../../../http-common";
// material-ui
import { makeStyles } from '@material-ui/styles';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography
} from '@material-ui/core';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import MessageDialog from 'views/utilities/MessageDialog';

import { getToken, removeUserSession, setUserSession } from 'utils/Sessions';


// style constant
const useStyles = makeStyles((theme) => ({
    redButton: {
        fontSize: '1rem',
        fontWeight: 500,
        // backgroundColor: theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.grey[100],
        color: theme.palette.grey[700],
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.light
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.875rem'
        }
    },
    signDivider: {
        flexGrow: 1
    },
    signText: {
        cursor: 'unset',
        margin: theme.spacing(2),
        padding: '5px 56px',
        borderColor: `${theme.palette.grey[100]} !important`,
        color: `${theme.palette.grey[900]}!important`,
        fontWeight: 500
    },
    loginIcon: {
        marginRight: '16px',
        [theme.breakpoints.down('sm')]: {
            marginRight: '8px'
        }
    },
    loginInput: {
        ...theme.typography.customInput
    }
}));

//= ===========================|| FIREBASE - LOGIN ||============================//

const FirebaseLogin = (props, { ...others }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const customization = useSelector((state) => state.customization);
    const scriptedRef = useScriptRef();
    const [checked, setChecked] = React.useState(true);

    const googleHandler = async () => {
        console.error('Login');
    };

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const [isClose, setIsClose] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    useEffect(() => {
        if (isClose) {
            setOpen(false)
            setIsClose(false)
        }
    }, [isClose]);

    const  url =  sessionStorage.getItem('url');
    return (
        <>
            <Formik
                initialValues={{
                    userid: '',
                    pwd: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    // userid: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    pwd: Yup.string().max(255).required('Password is required')
                })}

                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {

                        if (scriptedRef.current) {
                            console.log('liwat sini ==========================>>>>  ')
                            try {
                                const resp = await http.post('/api/doLogin', values);
                                const roles = resp.data.login;

                                if(roles.status === "02"){
                                    setOpen(true);
                                    
                                } else {
                                    values.pwd = '';
                                    sessionStorage.setItem('token', "1");
                                    sessionStorage.setItem('user', roles.userid);
                                    sessionStorage.setItem('nama', roles.namefull);
                                    sessionStorage.setItem('oldPassword', roles.oldPassword);
                                    sessionStorage.setItem('role', JSON.stringify(roles.role));
                                    sessionStorage.setItem('codeCabang', roles.kd_daerah);
                                    sessionStorage.setItem('wewenang', roles.wewenang);
                                    sessionStorage.setItem('namaCabang', roles.nm_cabang);

                                    navigate('/');
                                    window.location.reload();
                                    setStatus({ success: true });
                                    setSubmitting(false);
                                }
                            } catch (err) {
                                // Handle Error Here
                                console.error(err);
                            }
                          
                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                         <MessageDialog
                                    isOpen={open}
                                    isConfirmation={false}
                                    title='Informasi'
                                    handleClose={() => { setIsClose(true) }}
                                >
                                    user atau password salah
                                </MessageDialog>

                        <FormControl fullWidth error={Boolean(touched.userid && errors.userid)} className={classes.loginInput}>
                            <InputLabel htmlFor="outlined-adornment-email-login">User ID</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email-login"
                                // type="email"
                                value={values.userid}
                                name="userid"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label="User ID"
                                inputProps={{
                                    classes: {
                                        notchedOutline: classes.notchedOutline
                                    }
                                }}
                            />
                            {touched.userid && errors.userid && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {' '}
                                    {errors.userid}{' '}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl fullWidth error={Boolean(touched.pwd && errors.pwd)} className={classes.loginInput}>
                            <InputLabel htmlFor="outlined-adornment-password-login">Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password-login"
                                type={showPassword ? 'text' : 'password'}
                                value={values.pwd}
                                name="pwd"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                                inputProps={{
                                    classes: {
                                        notchedOutline: classes.notchedOutline
                                    }
                                }}
                            />
                            {touched.pwd && errors.pwd && (
                                <FormHelperText error id="standard-weight-helper-text-password-login">
                                    {' '}
                                    {errors.pwd}{' '}
                                </FormHelperText>
                            )}
                        </FormControl>
                   
                        {errors.submit && (
                            <Box
                                sx={{
                                    mt: 3
                                }}
                            >
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box
                            sx={{
                                mt: 2
                            }}
                        >
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleSubmit}
                                >
                                    Sign in

                                   
                                </Button>
                            </AnimateButton>
                        </Box>
                        <Stack alignItems="center" justifyContent="center" spacing={1}>
                            <Typography variant="caption" fontSize="18px" color="primaryDark">
                               ASKRIDA
                            </Typography>
                        </Stack>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default FirebaseLogin;
