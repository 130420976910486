import React from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';
import { getToken } from './utils/Sessions';
// routing
import Routes from './routes';

// defaultTheme
import themes from './themes';

// project imports
import NavigationScroll from './layout/NavigationScroll';

import Login3 from './views/login/Login3';
// ===========================|| APP ||=========================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    console.log(getToken() )

    if(getToken()!=='1'){
        return(
        <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
            <CssBaseline />
           <Login3/> 
           </ThemeProvider>
        </StyledEngineProvider>
        )

    } 
    return (
       
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
  
};

export default App;
