// return the user data from the session storage
export const getUser = () => {
  console.log()
    // const userStr = sessionStorage.getItem('user');
    // if (userStr) return JSON.parse(userStr);
    // else return null;
    return sessionStorage.getItem('user') || null;
  }
  
  // return the token from the session storage
  export const getToken = () => {
    console.log()
    return sessionStorage.getItem('token') || null;
  }

  export const getNama = () => {
    console.log()
    return sessionStorage.getItem('nama') || null;
  }

  export const getEmail = () => {
    console.log()
    return sessionStorage.getItem('email') || null;
  }

  export const getRole = () => {
    console.log()
    return sessionStorage.getItem('role') || null;
  }

  export const getNotificationsData = () => {
    console.log()
    return sessionStorage.getItem('notificationsData') || null;
  }

  export const getSideMenu = () => {
    console.log()
    return sessionStorage.getItem('sidemenu') || null;
  }

  export const getOldPassword = () => {
    console.log()
    return sessionStorage.getItem('oldPassword') || null;
  }

  export const getCodeCabang = () => {
    console.log()
    return sessionStorage.getItem('codeCabang') || null;
  }
  export const getWewenang = () => {
    console.log()
    return sessionStorage.getItem('wewenang') || null;
  }

  export const getNamaCabang = () => {
    console.log()
    return sessionStorage.getItem('namaCabang') || null;
  }
  
  
  // remove the token and user from the session storage
  export const removeUserSession = () => {
    console.log()
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('notificationsData');
    sessionStorage.removeItem('sidemenu');
    sessionStorage.removeItem('oldPassword');
    sessionStorage.removeItem('codeCabang');
    sessionStorage.removeItem('wewenang');
    sessionStorage.removeItem('namaCabang');
     
  }
  
  // set the token and user from the session storage
  export const setUserSession = (token, user, nama, email, role,oldPassword,codeCabang,wewenang,namaCabang, notificationsData,sidemenu) => {
    console.log()
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('user', user);
    sessionStorage.setItem('nama', nama);
    sessionStorage.setItem('email', email);
    sessionStorage.setItem('role', role);
    sessionStorage.setItem('oldPassword', oldPassword);
    sessionStorage.setItem('codeCabang', codeCabang);
    sessionStorage.setItem('wewenang', wewenang);
    sessionStorage.setItem('namaCabang', namaCabang);
    sessionStorage.setItem('notificationsData', notificationsData);
    sessionStorage.setItem('sidemenu', JSON.stringify(sidemenu));
  }