import React from 'react';
import { Link as RouterLink} from 'react-router-dom';
// material-ui
import { useTheme } from '@material-ui/core/styles';
import { Divider, Grid, Stack, Typography, useMediaQuery, Card } from '@material-ui/core';

// project imports
import AuthWrapper1 from '../pages/authentication/AuthWrapper1';
import AuthCardWrapper from '../pages/authentication/AuthCardWrapper';
import FirebaseLogin from '../pages/authentication/firebase-forms/FirebaseLogin';
import Logo from 'ui-component/Logo';
import AuthFooter from 'ui-component/cards/AuthFooter';

import { makeStyles } from '@material-ui/styles';

// assets
import logo from  'assets/images/loginywc.png'

//= ===============================|| AUTH3 - LOGIN ||================================//
// style constant
const useStyles = makeStyles((theme) => ({
    card: {
        background: 'linear-gradient(to right, #0063d4, #02b0fd)',
        marginTop: '16px',
        marginBottom: '16px',
        overflow: 'hidden',
        position: 'relative',
        '&:after': {
            content: '""',
            position: 'absolute',
            width: '200px',
            height: '200px',
            border: '19px solid ',
            borderColor: theme.palette.warning.main,
            borderRadius: '50%',
            top: '65px',
            right: '-150px'
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            width: '200px',
            height: '200px',
            border: '3px solid ',
            borderColor: theme.palette.warning.main,
            borderRadius: '50%',
            top: '145px',
            right: '-70px'
        }
    },
    tagLine: {
        color: theme.palette.grey[900],
        opacity: 0.6
    },
    button: {
        color: theme.palette.grey[800],
        backgroundColor: theme.palette.warning.main,
        textTransform: 'capitalize',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: theme.palette.warning.dark
        }
    }
}));

const Login = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    return (
        <AuthWrapper1>
       
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
           
                <Grid item xs={12}>
               
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                   
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                       
                            <AuthCardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    {/* ini untuk logo */}
                                    <Grid item lg={{ mb: 3 }}>
                                    <img src={logo} alt="Adikara" width="250" />
                                    </Grid>
                                    {/* <Grid item xs={12}> */}
                                        {/* <Grid
                                            container
                                            direction={matchDownSM ? 'column-reverse' : 'row'}
                                            alignItems="center"
                                            justifyContent="center"
                                        > */}
                                             {/* <Grid item>
                                                <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                    <Typography variant="caption" fontSize="18px" textAlign={matchDownSM ? 'center' : ''}>
                                                       Yes We Can
                                                    </Typography>
                                                </Stack>
                                            </Grid> */}
                                            
                                        {/* </Grid> */}
                                    {/* </Grid> */}
                                    <Grid item xs={12}>
                                        <FirebaseLogin login={3} />
                                    </Grid>
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                    <AuthFooter />
                </Grid>
                
            </Grid>
           
        </AuthWrapper1>
    );
};

export default Login;
