import React from 'react';
import ReactDOM from 'react-dom';

// third party
import { BrowserRouter ,HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import { store } from './store';
import * as serviceWorker from './serviceWorker';
import App from './App';

// style + assets
import './assets/scss/style.scss';

// ===========================|| REACT DOM RENDER  ||=========================== //

ReactDOM.render(
    <Provider store={store}>
        <HashRouter>
            <App />
        </HashRouter>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.register();

